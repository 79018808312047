import React, {useState} from 'react';
import {connectToStore} from './Provider';
import Link from './Link';
import Drawer from './Drawer';
import reduce from 'lodash/reduce';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import {Transition} from 'react-transition-group';
import Reveal from './Reveal';
import Banner from './Banner';
import Popup from './Popup';
import LandingPagePopup from './LandingPagePopup';
import Helmet from 'react-helmet';

const TRANSITION_DURATION = 600;

const UNDERLAY_DEFAULT_STYLE = {
	transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
	opacity: 0,
};

const UNDERLAY_TRANSITION_STYLES = {
	entering: {opacity: 0},
	entered: {opacity: 0.1},
	exited: {opacity: 0},
	exiting: {opacity: 0},
};

const Header = ({
					enableBanner,
					banner = [],
					enablePopup,
					popup = [],
					desktopMenu = [],
					shopMenuRows = [],
					shopMenuTiles = [],
					cartCount,
					openCart,
					openTakeover,
					discountBanner,
					location,
					bannerOverride,
					popupOverride,
				}) => {
	const [isShopMenuOpen, setShopMenuOpen] = useState(false);
	const onClose = () => setShopMenuOpen(false);

	const topBar = (
		<div className="grid-container contained">
			<div className="header__height df fdr jcb aic">
				<div className="fg0">
					<Link
						aria-label="Genexa-logo"
						url="/"
						className="db no-focus link--opacity"
						onClick={onClose}>
						<img
							alt="Genexa"
							className="db show--lg logo-with-tag"
							src="/images/logo-header-desktop.png"
						/>
						<img
							alt="Header logo Hide"
							className="db hide--lg logo-with-tag"
							src="/images/logo-header-desktop.png"
						/>
					</Link>
				</div>
				<div className="fg1">
					<ul className="df fdr aic jce">
						{desktopMenu.map(link => {
							if (link._type === 'shopMenuTrigger') {
								return (
									<li key={link._key} className="show--lg">
										<button
											title={link.title}
											aria-label={link.title}
											onClick={() =>
												setShopMenuOpen(true)
											}
											className="fw--800 p1 p2--xl link--trigger no-focus">
											<span className="link--underline">
												{link.title}
											</span>
										</button>
									</li>
								);
							}

							return (
								<li key={link._key} className="show--lg">
									<Link
										{...link}
										onClick={onClose}
										aria-label={link.title || link.collectionName || link.productName || link.pageName}
										className="fw--800 p1 p2--xl link--trigger no-focus">
										<span className="link--underline">
											{link.title ||
												link.collectionName ||
												link.productName ||
												link.pageName}
										</span>
									</Link>
								</li>
							);
						})}
					{/*	<li className="show--lg">
							<button
								title="Your Bag"
								aria-label="Your Bag"
								type="button"
								name="cart"
								className="fw--800 p1 p2--xl link--trigger no-focus"
								onClick={() => {
									onClose();
									openCart();
								}}>
								<span className="link--underline">
									Your Bag{' '}
									{cartCount > 0 && ` (${cartCount})`}
								</span>
							</button>
							</li>
						<li className="hide--lg">
							<button
								title="Cart"
								aria-label="Cart"
								type="button"
								name="cart"
								onClick={() => {
									onClose();
									openCart();
								}}
								className="db p1">
								<img
									alt="cart"
									className="db"
									src="/images/icon-cart.svg"
								/>
							</button>
						</li>*/}
						<li>
							<button
								title="Menu"
								aria-label="Menu"
								onClick={() => {
									onClose();
									openTakeover();
								}}
								type="button"
								name="menu"
								className="db p1 p3--lg pr0--lg pr0 link--opacity">
								<img
									alt="menu"
									className="db"
									src="/images/icon-menu.svg"
								/>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);

	const menuDropdown = (
		<Drawer renderTrigger={() => null} isOpen={isShopMenuOpen}>
			<Reveal isRevealed={isShopMenuOpen}>
				<div className="bg--white pt3 pb3 bt--black">
					<div className="grid-container contained">
						<div className="row">
							<div className="col c4">
								{shopMenuRows.map((row, index) => (
									<div
										key={row._key}
										className="reveal__fade"
										style={{
											transitionDelay: isShopMenuOpen
												? `${index * 200 + 200}ms`
												: '600ms',
										}}>
										{row.menu.map((menu, index) => (
											<p key={menu._key}>
												<Link
													{...menu}
													onClick={onClose}
													className="link--underline-small"
													aria-label="Menu"
												/>
											</p>
										))}
										<p>&nbsp;</p>
									</div>
								))}
							</div>
							{shopMenuTiles.map((tile, index) => (
								<div
									key={tile._key}
									className="col c2 reveal__slide"
									style={{
										transitionDelay: isShopMenuOpen
											? `${index * 200}ms`
											: '600ms',
									}}>
									<Link
										{...tile.cta}
										onClick={onClose}
										aria-label={tile.image.alt}
										className="link--opacity db mb1 ">
										<div
											className="image size--3x4"
											key={tile.image._key}>
											<picture>
												<source
													srcSet={`${tile.image.url}?w=900&auto=format&q=75`}
													media="(min-width: 1000px)"
												/>
												<source
													srcSet={`${tile.image.url}?w=600&auto=format&q=75`}
													media="(min-width: 600px)"
												/>
												<img
													alt={tile.image.alt}
													src={`${tile.image.url}?w=400&auto=format`}
												/>
											</picture>
										</div>
									</Link>
									<Link
										{...tile.cta}
										onClick={onClose}
										className="link--underline-small"
										aria-label="Shop menu"
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</Reveal>
		</Drawer>
	);

	const underlay = (
		<Transition
			in={isShopMenuOpen}
			mountOnEnter
			unmountOnExit
			appear
			timeout={{
				enter: 0,
				exit: TRANSITION_DURATION,
			}}>
			{state => (
				<div
					onClick={() => setShopMenuOpen(false)}
					className="header__underlay bg--black"
					style={{
						...UNDERLAY_DEFAULT_STYLE,
						...UNDERLAY_TRANSITION_STYLES[state],
					}}
				/>
			)}
		</Transition>
	);

	const discountRibbon = discountBanner && (
		<div className="bg--gray-bg show--lg">
			<div className="grid-container contained rich-text tc banner">
				<BlockContent blocks={discountBanner} serializers={richText} />
			</div>
		</div>
	);
	const bannerRibbon = enableBanner && <Banner banner={banner} bannerOverride={bannerOverride} />;
	const popupTooltip = enablePopup && (
		<Popup location={location} popup={popup} popupOverride={popupOverride} />
	);

	const landingPagePopup = typeof window !== 'undefined' && localStorage.getItem('landingPage') && (
		<LandingPagePopup popupOverride={popupOverride} />
	);

	return (
		<React.Fragment>
			{underlay}
			<header className="header bg--white">
				{bannerRibbon}
				{topBar}
				<div className="pr">
					<div className="pa top left right">{menuDropdown}</div>
				</div>
				{discountRibbon}
			</header>
			{popupTooltip}
			{landingPagePopup}

			<Helmet>
<script type='text/javascript' id='vwoCode'>
{`window._vwo_code=window._vwo_code || (function() {
var account_id=720040,
version = 1.5,
settings_tolerance=2000,
library_tolerance=2500,
use_existing_jquery=false,
is_spa=1,
hide_element='body',
hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
/* DO NOT EDIT BELOW THIS LINE */
f=false,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{'+hide_element_style+'}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());`}
</script>

<script type="text/javascript">
			{`var _iub = _iub || [];
	_iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"countryDetection":true,"enableLgpd":true,"enableUspr":true,"floatingPreferencesButtonDisplay":"bottom-right","lang":"en","lgpdAppliesGlobally":false,"perPurposeConsent":true,"siteId":3192423,"whitelabel":false,"cookiePolicyId":35951043, "banner":{ "acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center","rejectButtonDisplay":true,"showPurposesToggles":true }};`}
</script>
				<script type="text/javascript">{'var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "mRyhBPXyXytTFUSF9npZStydH6Vnj3af"}]);'}</script>
				<script type="text/javascript" src="https://cdn.iubenda.com/cons/iubenda_cons.js" async></script>

	<script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3192423.js"></script>
	<script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js"></script>
	<script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>

</Helmet>

		</React.Fragment>
	);
};


const mapStateToProps = ({checkout, customer}) => ({
	customer,
	cartCount: checkout
		? reduce(
			checkout.lineItems,
			(runningTotal, item) => item.quantity + runningTotal,
			0,
		)
		: 0,
});

const mapStoreToProps = ({openCart, openTakeover}) => ({
	openCart,
	openTakeover,
});

export default connectToStore(mapStateToProps, mapStoreToProps)(Header);
